<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-md-12 col-lg-12">
          <c-card title="화학자재 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-material
                  :required="true"
                  :editable="editable && isUpdate"
                  label="자재코드/명"
                  name="materialCd"
                  type="codename"
                  :plantCd="data.plantCd"
                  v-model="data.materialCd">
                </c-material>
              </div>
              <div class="col-3">
                <c-text
                  :editable="false"
                  label="화학자재일련번호"
                  name="mdmChemMaterialId"
                  v-model="data.mdmChemMaterialId">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd" />
              </div>
              <div class="col-3">
                <c-vendor
                  :editable="editable"
                  label="납품업체"
                  name="deliveryVendorCd"
                  v-model="data.deliveryVendorCd">
                </c-vendor>
              </div>
              <div class="col-3">
                <c-vendor
                  :editable="editable"
                  label="제조업체"
                  name="mfgVendorCd"
                  v-model="data.mfgVendorCd">
                </c-vendor>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="보관장소"
                  name="storagePlace"
                  v-model="data.storagePlace">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="PROPERTIES_STATE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="propertiesStateCd"
                  label="성상"
                  v-model="data.propertiesStateCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="비산성(고체)"
                  name="fugacity"
                  v-model="data.fugacity">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="끓는점(액체,기체)"
                  name="boilPoint"
                  v-model="data.boilPoint">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="녹는점"
                  name="meltingPoint"
                  v-model="data.meltingPoint">
                </c-text>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="분자량"
                  name="col7"
                  v-model="data.col7">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  codeGroupCd="USAGE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="usageCd"
                  label="용도"
                  v-model="data.usageCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable && data.usageCd=='UC00000032'"
                  label="용도(기타)"
                  name="usageEtc"
                  v-model="data.usageEtc">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :comboItems="mixFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="mixFlag"
                  label="물질구성"
                  v-model="data.mixFlag"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :editable="editable"
                  label="비중"
                  name="specificGravity"
                  v-model="data.specificGravity">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="CAS No."
                  name="casNo"
                  v-model="data.casNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="고유번호"
                  name="serialNo"
                  v-model="data.serialNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="KE 번호"
                  name="keNo"
                  v-model="data.keNo">
                </c-text>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable"
                  label="HS번호"
                  name="hsNo"
                  v-model="data.hsNo">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-md-6 col-lg-6">
          <c-card class="cardClassDetailForm" :noHeader="true">
            <template slot="card-detail">
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="인허가대상 물질 여부"
                  name="licensingFlag"
                  v-model="data.licensingFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="위험물 여부"
                  name="dangerFlag"
                  v-model="data.dangerFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="사용여부"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <div class="col-3">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="유해화학물질(PSM) 여부"
                  name="psmFlag"
                  v-model="data.psmFlag"
                  @datachange="psmChange"
                />
                  <!-- @datachange="(val) => { this.psmFlag.bool = val === 'Y', this.psmFlag.watch = uid() }" -->
              </div>
              <div class="col-12">
                <font class="formLabelTitle txtlabel">제품 분류 </font>
                <q-checkbox
                  v-model="data.makeFlag"
                  dense
                  color="orange-7"
                  label="제조"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.impFlag"
                  dense
                  color="orange-7"
                  label="수입"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.buyFlag"
                  dense
                  color="orange-7"
                  label="구매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.usingFlag"
                  dense
                  color="orange-7"
                  label="사용"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.expFlag"
                  dense
                  color="orange-7"
                  label="수출"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.salesFlag"
                  dense
                  color="orange-7"
                  label="판매"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
              <div class="col-12">
                <font class="formLabelTitle txtlabel">유해화학물질 포함 여부 </font>
                <q-checkbox
                  v-model="data.toxicPoisonFlag"
                  dense
                  color="orange-7"
                  label="유독물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.toxicPermitFlag"
                  dense
                  color="orange-7"
                  label="허가물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.toxicLimitFlag"
                  dense
                  color="orange-7"
                  label="제한물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
                <q-checkbox
                  v-model="data.toxicProhibitFlag"
                  dense
                  color="orange-7"
                  label="금지물질"
                  true-value="Y"
                  false-value="N"
                  class="customqcbox"
                />
              </div>
            </template>
          </c-card>
        </div>
        <!-- <div class="col-md-6 col-lg-6">
          <c-table
            ref="deptTable"
            title="취급부서 목록"
            tableId="deptTable"
            :columns="grid.columns"
            :data="grid.data"
            :columnSetting="false"
            selection="multiple"
            rowKey="deptCd"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="305px"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="추가" v-if="editable && !isUpdate" icon="add" @btnClicked="addrow" />
                <c-btn label="저장" v-if="editable && !isUpdate" icon="save" @btnClicked="saveDepts" />
                <c-btn label="삭제" v-if="editable && !isUpdate" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
          </c-table>
        </div> -->
        <div class="col-md-12 col-lg-12">
          <c-card class="cardClassDetailForm" title="기타 정보">
            <template slot="card-detail">
              <div class="col-2">
                <c-text
                  :editable="editable"
                  label="끓는점"
                  type="number"
                  name="boilingPoint"
                  v-model="data.boilingPoint">
                </c-text>
              </div>
              <div class="col-2">
                <c-select
                  :editable="editable"
                  :comboItems="cmrFlagItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="specialCtype"
                  label="발암성(C)"
                  v-model="data.specialCtype"
                  @input="changeCMR"
                ></c-select>
              </div>
              <div class="col-2">
                <c-select
                  :comboItems="cmrFlagItems"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="specialMtype"
                  label="생식세포 변이원성(M)"
                  v-model="data.specialMtype"
                  @input="changeCMR"
                ></c-select>
              </div>
              <div class="col-2">
                <c-select
                  :comboItems="cmrFlagItems"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="specialRtype"
                  label="생식독성(R)"
                  v-model="data.specialRtype"
                  @input="changeCMR"
                ></c-select>
              </div>
              <div class="col-2">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="CMR물질여부"
                  name="cmrFlag"
                  v-model="data.cmrFlag"
                />
              </div>
              <div class="col-2">
                <c-select
                  :comboItems="gradeItems"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="hrCodeGrade"
                  label="위험문구/유해위험문구 등급"
                  v-model="data.hrCodeGrade"
                ></c-select>
              </div>
              <div class="col-2">
                <c-select
                  :comboItems="formItems"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="maleficenceForm"
                  label="유해성 발생형태"
                  v-model="data.maleficenceForm"
                ></c-select>
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable"
                  type="number"
                  label="유해성 측정값"
                  name="maleficenceValue"
                  v-model="data.maleficenceValue">
                </c-text>
              </div>
              <div class="col-1">
                <c-select
                  codeGroupCd="MALEFICENCE_TYPE"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="maleficenceType"
                  label="비산/휘발성 구분"
                  v-model="data.maleficenceType"
                ></c-select>
              </div>
              <div class="col-1">
                <c-select
                  :comboItems="gradeItems"
                  :editable="editable"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="maleficenceGrade"
                  label="비산성 등급"
                  v-model="data.maleficenceGrade"
                ></c-select>
              </div>
              <!-- <div class="col-2">
                <c-text
                  :editable="editable"
                  label="하루취급량"
                  name="dayVolume"
                  type="number"
                  v-model="data.dayVolume">
                </c-text>
              </div>
              <div class="col-2">
                <c-select
                  :editable="editable"
                  codeGroupCd="DAILY_VOLUME_UNIT"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="dayVolumeUnit"
                  label="하루취급량 단위"
                  v-model="data.dayVolumeUnit"
                ></c-select>
              </div> -->
              <div class="col-3">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="위험문구(R-phrase)"
                  name="rcode"
                  v-model="data.rcode">
                </c-textarea>
              </div>
              <div class="col-3">
                <c-textarea
                  :editable="editable"
                  :rows="1"
                  label="유해위험문구(H-code)"
                  name="hcode"
                  v-model="data.hcode">
                </c-textarea>
              </div>
              <!-- <div class="col-12">
                <c-textarea
                  :counter="true"
                  :maxlength="500"
                  :editable="editable"
                  :rows="10"
                  label="비고"
                  name="remarks"
                  v-model="data.remarks">
                </c-textarea>
              </div> -->
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
      }),
    },
    psmFlag: {
      type: Object,
      default: () => ({
        bool: false,
        watch: '',
      }),
    },
  },
  data() {
    return {
      isUpdate: true,
      saveUrl: transactionConfig.mdm.mam.chem.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      processListUrl: '',
      processDeleteUrl: '',
      processSaveUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      // grid: {
      //   columns: [
      //     {
      //       name: 'plantName',
      //       field: 'plantName',
      //       label: '사업장',
      //       align: 'center',
      //       style: 'width:200px',
      //       sortable: true,
      //     },
      //     {
      //       name: 'deptCd',
      //       field: 'deptCd',
      //       label: '부서코드',
      //       align: 'center',
      //       style: 'width:200px',
      //       sortable: true,
      //     },
      //     {
      //       name: 'deptName',
      //       field: 'deptName',
      //       label: '부서명',
      //       align: 'center',
      //       sortable: true,
      //     },
      //   ],
      //   data: [],
      // },
      gridprocess: {
        columns: [
          {
            name: 'processCd',
            field: 'processCd',
            label: '공정코드',
            align: 'center',
            style: 'width:120px',
            sortable: false,
          },
          {
            name: 'processName',
            field: 'processName',
            label: '공정명',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '제조 또는 사용여부',
            align: 'center',
            style: 'width:150px',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            name: 'usePurpose',
            field: 'usePurpose',
            label: '사용용도',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'dailyVolume',
            field: 'dailyVolume',
            label: '일 취급량',
            align: 'center',
            type: 'number',
            style: 'width:100px',
            sortable: false,
          },
        ],
        data: [],
      },
      data: {
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        materialCd: '',  // 자재코드
        deliveryVendorCd: '',  // 납품업체 코드
        mfgVendorCd: '',  // 제조업체 코드
        psmFlag: 'N',  // PSM 대상 여부
        propertiesStateCd: null,  // 성상-공통코드
        fugacity: '',  // 비산성(고체)
        boilPoint: '',  // 끊는점(액체,기체)
        meltingPoint: '',  // 녹는점
        moleWeight: '',  // 분자량
        usageCd: null,  // 용도-회사별 공통코드
        usageEtc: '',  // 용도 기타
        hsNo: '',  // HS번호
        specificGravity: '',  // 비중
        casNo: '',  // CAS No.
        serialNo: '',  // 고유번호(유독물번호or고시번호)
        keNo: '',  // KE 번호
        storagePlace: '',  // 보관장소
        productCategoryCd: null,  // 제품구분
        mixFlag: 'N',  // 물질구성(단일 N/혼합 Y)
        licensingFlag: 'N',  // 인허가대상 물질 여부 Y/N
        dangerFlag: 'N',  // 위험물 여부Y/N
        makeFlag: 'N',  // 제품분류(제조)
        impFlag: 'N',  // 제품분류(수입)
        buyFlag: 'N',  // 제품분류(구매)
        usingFlag: 'N',  // 제품분류(사용)
        expFlag: 'N',  // 제품분류(수출)
        salesFlag: 'N',  // 제품분류(판매)
        toxicPoisonFlag: 'N',  // 유해화학물질포함여부(유독물질)
        toxicPermitFlag: 'N',  // 유해화학물질포함여부(허가물질)
        toxicLimitFlag: 'N',  // 유해화학물질포함여부(제한물질)
        toxicProhibitFlag: 'N',  // 유해화학물질포함여부(금지물질)
        remarks: '',  // 비고
        specialCtype: null,  // 특별관리물질 C
        specialRtype: null,  // 특별관리물질 R
        specialMtype: null,  // 특별관리물질 M
        boilingPoint: null,
        hrCodeGrade: null,
        maleficenceForm: null,
        maleficenceType: null,
        maleficenceGrade: null,
        maleficenceValue: '',
        dayVolume: '',  // 하루취급량
        dayVolumeUnit: null,  // 하루취급량 단위
        rcode: '',  // 위험문구(R-code)
        hcode: '',  // 유해위험문구(H-code)
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      savePsmFlag: '',
      mixFlagItems: [
        { code: 'Y', codeName: '혼합' },
        { code: 'N', codeName: '단일' },
      ],
      cmrFlagItems: [
        { code: '1A', codeName: '1A' },
        { code: '1B', codeName: '1B' },
        { code: '2', codeName: '2' },
      ],
      gradeItems: [
        { code: '4', codeName: '최대' },
        { code: '3', codeName: '대' },
        { code: '2', codeName: '중' },
        { code: '1', codeName: '소' },
      ],
      formItems: [
        { code: '분진', codeName: '분진(㎎/㎥)' },
        { code: '증기', codeName: '증기(ppm)' },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.mdm.mam.chem.get.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.checkUrl = selectConfig.mdm.mam.chem.check.url;
      // this.deptListUrl = selectConfig.mdm.mam.chem.dept.url;
      // this.deptDeleteUrl = transactionConfig.mdm.mam.chem.dept.delete.url;
      // this.deptSaveUrl = transactionConfig.mdm.mam.chem.dept.insert.url;
      this.processListUrl = selectConfig.mdm.mam.chem.process.url;
      this.processDeleteUrl = transactionConfig.mdm.mam.chem.process.delete.url;
      this.processSaveUrl = transactionConfig.mdm.mam.chem.process.insert.url;
      this.getDetail();
    },
    getDetail() {
      if (this.param.mdmChemMaterialId) {
        this.$http.url = this.$format(this.detailUrl, this.param.mdmChemMaterialId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          if (_result.data.mdmChemMaterialId) {
            this.isUpdate = false;
          }
          this.param.plantCd = _result.data.plantCd;
          this.param.plantName = _result.data.plantName;
          this.param.materialCd = _result.data.materialCd;
          this.param.materialName = _result.data.materialName;
          this.updateMode = true;
          this.getProcessList();

          /**
           * PSM 동작 처리
           */
          this.savePsmFlag = this.$_.clone(this.data.psmFlag)
          this.psmChange(this.data.psmFlag)
        },);
      }
    },
    psmChange(val) {
      this.psmFlag.bool = val === 'Y';
      this.psmFlag.watch = uid()
    },
    // getDeptList() {
    //   this.$http.url = this.deptListUrl;
    //   this.$http.type = 'GET';
    //   this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
    //   this.$http.request((_result) => {
    //     this.grid.data = _result.data;
    //   },);
    // },
    // addrow() {
    //   this.popupOptions.title = '취급부서 추가';
    //   this.popupOptions.param = {
    //     plantCd: this.data.plantCd
    //   }
    //   this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
    //   this.popupOptions.visible = true;
    //   this.popupOptions.closeCallback = this.closeDept;
    // },
    // closeDept(data) {
    //   this.popupOptions.target = null;
    //   this.popupOptions.visible = false;
    //   if (data && data.length > 0) {
    //     this.$_.forEach(data, item => {
    //       if (this.$_.findIndex(this.grid.data, { deptCd: item.deptCd }) === -1) {
    //         this.grid.data.push({
    //           mdmChemMaterialId: this.param.mdmChemMaterialId,
    //           plantCd: item.plantCd,
    //           plantName: item.plantName,
    //           deptCd: item.deptCd,
    //           deptName: item.deptName,
    //           editFlag: 'C',
    //         })
    //       }
    //     })
    //   }
    // },
    // saveDepts() {
    //   let saveData = this.grid.data.filter( x => {
    //     return x.editFlag == 'C'
    //   });
    //   if(saveData.length > 0) {
    //     window.getApp.$emit('CONFIRM', {
    //       title: '확인',
    //       message: '저장하시겠습니까?',
    //       type: 'info',
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.deptSaveUrl;
    //         this.$http.type = 'POST';
    //         this.$http.param = saveData;
    //         this.$http.request(() => {
    //           this.getDeptList();
    //           window.getApp.$emit('ALERT', {
    //             title: '안내 ', /* 안내 */
    //             message: '저장되었습니다.', /* 저장되었습니다. */
    //             type: 'success', // success / info / warning / error
    //           });
    //         });
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   } else {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내 ', /* 안내 */
    //       message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
    //       type: 'info', // success / info / warning / error
    //     });
    //   }
    // },
    // removeRow() {
    //   let selectData = this.$refs['deptTable'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내', // 안내
    //       message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //       title: '확인',
    //       message: '삭제하시겠습니까?',
    //       type: 'warning', // success / info / warning / error
    //       // 확인 callback 함수
    //       confirmCallback: () => {
    //         this.$http.url = this.$format(this.deptDeleteUrl);
    //         this.$http.type = 'DELETE';
    //         this.$http.param = {
    //           data: Object.values(selectData)
    //         };
    //         this.$http.request(() => {
    //           this.getDeptList();
    //           window.getApp.$emit('ALERT', {
    //             title: '안내 ', /* 안내 */
    //             message: '삭제되었습니다.', /* 삭제되었습니다. */
    //             type: 'success', // success / info / warning / error
    //           });
    //         },);
    //       },
    //       // 취소 callback 함수
    //       cancelCallback: () => {
    //       },
    //     });
    //   }
    // },
    getProcessList() {
      this.$http.url = this.processListUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.gridprocess.data = _result.data;
      },);
    },
    addrowProcess() {
      this.popupOptions.title = '단위공정 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '20'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.gridprocess.data, { processCd: item.processCd }) === -1) {
            this.gridprocess.data.push({
              chemMaterialProcessId: uid(),
              mdmChemMaterialId: this.param.mdmChemMaterialId,
              processCd: item.processCd,
              processName: item.processName,
              useFlag: 'N',
              usePurpose: '',
              dailyVolume: '',
              editFlag: 'C',
            })
          }
        })
      }
    },
    saveProcess() {
      let saveData = this.gridprocess.data.filter( x => {
        return x.editFlag == 'C' || x.editFlag == 'U'
      });
      if(saveData.length > 0) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          type: 'info',
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.processSaveUrl;
            this.$http.type = 'POST';
            this.$http.param = saveData;
            this.$http.request(() => {
              this.getProcessList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내 ', /* 안내 */
          message: '변경된 데이터가 없습니다.', /* 변경된 데이터가 없습니다. */
          type: 'info', // success / info / warning / error
        });
      }
    },
    removeRowProcess() {
      let selectData = this.$refs['processTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.processDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getProcessList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');

              this.$_.forEach(selectData, item => {
                this.gridprocess.data = this.$_.reject(this.gridprocess.data, item);
              })
              this.$refs['processTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfo() {
      if (this.param.mdmChemMaterialId) {
        this.saveUrl = transactionConfig.mdm.mam.chem.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = '저장하시겠습니까?';
          if (this.savePsmFlag !== this.data.psmFlag && this.data.psmFlag !== 'Y') {
            message = '저장하시겠습니까?\n\r※ PSM 여부가 "No"로 변경되었습니다.\n\r  유해화학물질(PSM)정보는 지워집니다.'
          }
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              this.isSave = true;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      this.param.mdmChemMaterialId = result.data.mdmChemMaterialId;
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.$emit('insertEquipInfo', result.data.mdmChemMaterialId);
      }
      this.getDetail();
    },
    changeCMR() {
      if (this.data.specialCtype !== null || this.data.specialMtype !== null || this.data.specialRtype !== null) {
        this.data.cmrFlag = 'Y';
      } else {
        this.data.cmrFlag = 'N';
      }
    }
  }
};
</script>