var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "화학자재 기본정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.data,
                                  mappingType: _vm.mappingType,
                                  label: "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-material", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && _vm.isUpdate,
                            label: "자재코드/명",
                            name: "materialCd",
                            type: "codename",
                            plantCd: _vm.data.plantCd,
                          },
                          model: {
                            value: _vm.data.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "materialCd", $$v)
                            },
                            expression: "data.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: false,
                            label: "화학자재일련번호",
                            name: "mdmChemMaterialId",
                          },
                          model: {
                            value: _vm.data.mdmChemMaterialId,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mdmChemMaterialId", $$v)
                            },
                            expression: "data.mdmChemMaterialId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.data.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "plantCd", $$v)
                            },
                            expression: "data.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "납품업체",
                            name: "deliveryVendorCd",
                          },
                          model: {
                            value: _vm.data.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "deliveryVendorCd", $$v)
                            },
                            expression: "data.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            label: "제조업체",
                            name: "mfgVendorCd",
                          },
                          model: {
                            value: _vm.data.mfgVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mfgVendorCd", $$v)
                            },
                            expression: "data.mfgVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "보관장소",
                            name: "storagePlace",
                          },
                          model: {
                            value: _vm.data.storagePlace,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "storagePlace", $$v)
                            },
                            expression: "data.storagePlace",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.data.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "propertiesStateCd", $$v)
                            },
                            expression: "data.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비산성(고체)",
                            name: "fugacity",
                          },
                          model: {
                            value: _vm.data.fugacity,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "fugacity", $$v)
                            },
                            expression: "data.fugacity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "끓는점(액체,기체)",
                            name: "boilPoint",
                          },
                          model: {
                            value: _vm.data.boilPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "boilPoint", $$v)
                            },
                            expression: "data.boilPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "녹는점",
                            name: "meltingPoint",
                          },
                          model: {
                            value: _vm.data.meltingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "meltingPoint", $$v)
                            },
                            expression: "data.meltingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "분자량",
                            name: "col7",
                          },
                          model: {
                            value: _vm.data.col7,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "col7", $$v)
                            },
                            expression: "data.col7",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.data.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usageCd", $$v)
                            },
                            expression: "data.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable && _vm.data.usageCd == "UC00000032",
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.data.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usageEtc", $$v)
                            },
                            expression: "data.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.mixFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "mixFlag",
                            label: "물질구성",
                          },
                          model: {
                            value: _vm.data.mixFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "mixFlag", $$v)
                            },
                            expression: "data.mixFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비중",
                            name: "specificGravity",
                          },
                          model: {
                            value: _vm.data.specificGravity,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specificGravity", $$v)
                            },
                            expression: "data.specificGravity",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "CAS No.",
                            name: "casNo",
                          },
                          model: {
                            value: _vm.data.casNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "casNo", $$v)
                            },
                            expression: "data.casNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "고유번호",
                            name: "serialNo",
                          },
                          model: {
                            value: _vm.data.serialNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "serialNo", $$v)
                            },
                            expression: "data.serialNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "KE 번호",
                            name: "keNo",
                          },
                          model: {
                            value: _vm.data.keNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "keNo", $$v)
                            },
                            expression: "data.keNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "HS번호",
                            name: "hsNo",
                          },
                          model: {
                            value: _vm.data.hsNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hsNo", $$v)
                            },
                            expression: "data.hsNo",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-6 col-lg-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { noHeader: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.data.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "licensingFlag", $$v)
                            },
                            expression: "data.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.data.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "dangerFlag", $$v)
                            },
                            expression: "data.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "사용여부",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.data.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "useFlag", $$v)
                            },
                            expression: "data.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "유해화학물질(PSM) 여부",
                            name: "psmFlag",
                          },
                          on: { datachange: _vm.psmChange },
                          model: {
                            value: _vm.data.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "psmFlag", $$v)
                            },
                            expression: "data.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("제품 분류 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "제조",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.makeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "makeFlag", $$v)
                            },
                            expression: "data.makeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "수입",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.impFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "impFlag", $$v)
                            },
                            expression: "data.impFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "구매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.buyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "buyFlag", $$v)
                            },
                            expression: "data.buyFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "사용",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.usingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "usingFlag", $$v)
                            },
                            expression: "data.usingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "수출",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.expFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "expFlag", $$v)
                            },
                            expression: "data.expFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "판매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.salesFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "salesFlag", $$v)
                            },
                            expression: "data.salesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("유해화학물질 포함 여부 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "유독물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.toxicPoisonFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "toxicPoisonFlag", $$v)
                            },
                            expression: "data.toxicPoisonFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "허가물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.toxicPermitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "toxicPermitFlag", $$v)
                            },
                            expression: "data.toxicPermitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "제한물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.toxicLimitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "toxicLimitFlag", $$v)
                            },
                            expression: "data.toxicLimitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            dense: "",
                            color: "orange-7",
                            label: "금지물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.data.toxicProhibitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "toxicProhibitFlag", $$v)
                            },
                            expression: "data.toxicProhibitFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-12 col-lg-12" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "기타 정보" },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "끓는점",
                            type: "number",
                            name: "boilingPoint",
                          },
                          model: {
                            value: _vm.data.boilingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "boilingPoint", $$v)
                            },
                            expression: "data.boilingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            editable: _vm.editable,
                            comboItems: _vm.cmrFlagItems,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "specialCtype",
                            label: "발암성(C)",
                          },
                          on: { input: _vm.changeCMR },
                          model: {
                            value: _vm.data.specialCtype,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specialCtype", $$v)
                            },
                            expression: "data.specialCtype",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.cmrFlagItems,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "specialMtype",
                            label: "생식세포 변이원성(M)",
                          },
                          on: { input: _vm.changeCMR },
                          model: {
                            value: _vm.data.specialMtype,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specialMtype", $$v)
                            },
                            expression: "data.specialMtype",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.cmrFlagItems,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "specialRtype",
                            label: "생식독성(R)",
                          },
                          on: { input: _vm.changeCMR },
                          model: {
                            value: _vm.data.specialRtype,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "specialRtype", $$v)
                            },
                            expression: "data.specialRtype",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "CMR물질여부",
                            name: "cmrFlag",
                          },
                          model: {
                            value: _vm.data.cmrFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "cmrFlag", $$v)
                            },
                            expression: "data.cmrFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.gradeItems,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "hrCodeGrade",
                            label: "위험문구/유해위험문구 등급",
                          },
                          model: {
                            value: _vm.data.hrCodeGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hrCodeGrade", $$v)
                            },
                            expression: "data.hrCodeGrade",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.formItems,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "maleficenceForm",
                            label: "유해성 발생형태",
                          },
                          model: {
                            value: _vm.data.maleficenceForm,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "maleficenceForm", $$v)
                            },
                            expression: "data.maleficenceForm",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-2" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            type: "number",
                            label: "유해성 측정값",
                            name: "maleficenceValue",
                          },
                          model: {
                            value: _vm.data.maleficenceValue,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "maleficenceValue", $$v)
                            },
                            expression: "data.maleficenceValue",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "MALEFICENCE_TYPE",
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "maleficenceType",
                            label: "비산/휘발성 구분",
                          },
                          model: {
                            value: _vm.data.maleficenceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "maleficenceType", $$v)
                            },
                            expression: "data.maleficenceType",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-1" },
                      [
                        _c("c-select", {
                          attrs: {
                            comboItems: _vm.gradeItems,
                            editable: _vm.editable,
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "maleficenceGrade",
                            label: "비산성 등급",
                          },
                          model: {
                            value: _vm.data.maleficenceGrade,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "maleficenceGrade", $$v)
                            },
                            expression: "data.maleficenceGrade",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 1,
                            label: "위험문구(R-phrase)",
                            name: "rcode",
                          },
                          model: {
                            value: _vm.data.rcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "rcode", $$v)
                            },
                            expression: "data.rcode",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-textarea", {
                          attrs: {
                            editable: _vm.editable,
                            rows: 1,
                            label: "유해위험문구(H-code)",
                            name: "hcode",
                          },
                          model: {
                            value: _vm.data.hcode,
                            callback: function ($$v) {
                              _vm.$set(_vm.data, "hcode", $$v)
                            },
                            expression: "data.hcode",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }